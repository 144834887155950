<template>
  <div class="specials-block">
    <content-wrapper-block no-overflow>
      <page-title class="specials-block__title"> Спецпредложения </page-title>

      <div class="specials-block__slider">
        <swiper
          ref="swiper"
          class="specials-block__swiper"
          :options="{
            slidesPerView: 'auto',
            centeredSlides: true,
            spaceBetween: 0,
            pagination: {
              el: '.swiper-specials-pagination',
              clickable: true,
            },
          }"
        >
          <swiper-slide
            v-for="(special, index) in specialsState"
            :key="index"
            class="specials-block__slide"
          >
            <action-card
              :title="special.title"
              :image="special.image"
              :to="special.to"
            >
              {{ special.description }}
            </action-card>
          </swiper-slide>
          <swiper-red-pagination
            v-if="false"
            slot="pagination"
            pagination-white
            class="swiper-specials-pagination"
          />
        </swiper>
      </div>
      <div v-if="false" class="specials-block__footer">
        <router-link :to="{ name: 'StocksPage' }">
          <ui-button>все спецпредложения</ui-button>
        </router-link>
      </div>
    </content-wrapper-block>
  </div>
</template>

<script>
import ContentWrapperBlock from "@/components/blocks/ContentWrapperBlock";
import PageTitle from "@/components/elements/PageTitle";
import ActionCard from "@/components/elements/ActionCard";
import UiButton from "@/components/ui/UIButton";
import SwiperRedPagination from "@/components/elements/SwiperRedPagination";
import { mapState } from "vuex";

export default {
  name: "SpecialsBlock",
  components: {
    SwiperRedPagination,
    UiButton,
    ActionCard,
    PageTitle,
    ContentWrapperBlock,
  },
  computed: {
    ...mapState({
      specialsState: (state) => state.specials,
    }),
  },
};
</script>

<style lang="scss">
.specials-block {
  overflow: hidden;
  padding-bottom: 50px;

  &__title {
    padding-bottom: 30px;
  }

  &__swiper {
    overflow: visible;

    .swiper-slide {
      opacity: 0;
      transform: translateX(0px) scale(0.8);
    }

    .swiper-slide-active {
      opacity: 1;
      transform: none;
    }

    .swiper-slide-prev {
      opacity: 0.5;
      transform: translateX(50px) scale(0.8);
    }
    .swiper-slide-next {
      opacity: 0.5;
      transform: translateX(-50px) scale(0.8);
    }
  }

  &__slide {
    width: 90%;
    transition: all 300ms ease-out;

    @media screen and (min-width: $screen-md) {
      width: 900px;
    }
  }
}
.swiper-specials-pagination {
  margin-top: 30px;
}
</style>

<style scoped lang="scss">
.specials-block {
  color: #ffffff;
  background-color: #2e2a2b;

  &__footer {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    margin-top: 30px;
  }
}
</style>
