var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "specials-block" },
    [
      _c(
        "content-wrapper-block",
        { attrs: { "no-overflow": "" } },
        [
          _c("page-title", { staticClass: "specials-block__title" }, [
            _vm._v(" Спецпредложения "),
          ]),
          _c(
            "div",
            { staticClass: "specials-block__slider" },
            [
              _c(
                "swiper",
                {
                  ref: "swiper",
                  staticClass: "specials-block__swiper",
                  attrs: {
                    options: {
                      slidesPerView: "auto",
                      centeredSlides: true,
                      spaceBetween: 0,
                      pagination: {
                        el: ".swiper-specials-pagination",
                        clickable: true,
                      },
                    },
                  },
                },
                [
                  _vm._l(_vm.specialsState, function (special, index) {
                    return _c(
                      "swiper-slide",
                      { key: index, staticClass: "specials-block__slide" },
                      [
                        _c(
                          "action-card",
                          {
                            attrs: {
                              title: special.title,
                              image: special.image,
                              to: special.to,
                            },
                          },
                          [_vm._v(" " + _vm._s(special.description) + " ")]
                        ),
                      ],
                      1
                    )
                  }),
                  false
                    ? _c("swiper-red-pagination", {
                        staticClass: "swiper-specials-pagination",
                        attrs: { slot: "pagination", "pagination-white": "" },
                        slot: "pagination",
                      })
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          false
            ? _c(
                "div",
                { staticClass: "specials-block__footer" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: { name: "StocksPage" } } },
                    [_c("ui-button", [_vm._v("все спецпредложения")])],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }