var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contact" },
    [
      _c(
        "content-wrapper-block",
        [
          _c("page-title", { attrs: { white: "" } }, [_vm._v(" контакты ")]),
          _c(
            "div",
            { staticClass: "contact-items" },
            [
              _c(
                "card-adress",
                {
                  attrs: {
                    title: "ЖК MAINSTREET",
                    address: _vm.buildingAddress,
                    "work-time": _vm.buildingWorkTime,
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "icon" },
                    [_c("icon-location", { attrs: { color: "#E14313" } })],
                    1
                  ),
                ],
                2
              ),
              _c(
                "card-adress",
                {
                  attrs: {
                    title: "офис продаж",
                    address: _vm.ofiiceAddress,
                    "work-time": _vm.officeWorkTime,
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "icon" },
                    [_c("icon-location", { attrs: { color: "#83B4FF" } })],
                    1
                  ),
                ],
                2
              ),
              _c(
                "div",
                {
                  staticClass:
                    "contact__docs item item_mobile--hide item_line item_indent",
                },
                [
                  _c("div", { staticClass: "item__title" }, [
                    _vm._v("Документация"),
                  ]),
                  _c(
                    "div",
                    _vm._l(_vm.documents, function (item, index) {
                      return _c("documents-item", {
                        key: index,
                        attrs: {
                          "file-name": item.data.original_name,
                          "file-date": item.data.date,
                          "file-u-r-l":
                            "https://api.gkosnova.tech/public/api/v1/download/" +
                            item.data.id,
                          target: "__blank",
                          light: "",
                          "icon-color": "#fff",
                        },
                      })
                    }),
                    1
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "item__link",
                      attrs: { to: { name: "DocumentsPage" } },
                    },
                    [_vm._v(" Все документы ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "contact-map" },
        [
          _c("Map"),
          _c(
            "content-wrapper-block",
            {
              staticClass:
                "contact-map contact-map__item contact-map__item_hide--desctop",
            },
            [
              _c("div", { staticClass: "item" }, [
                _c("div", { staticClass: "item__title" }, [
                  _vm._v("Документация"),
                ]),
                _c(
                  "div",
                  { staticClass: "item__docs" },
                  _vm._l(_vm.documents, function (item, index) {
                    return _c("documents-item", {
                      key: index,
                      attrs: {
                        "file-name": item.data.original_name,
                        "file-date": item.data.date,
                        "file-u-r-l":
                          "https://api.gkosnova.tech/public/api/v1/download/" +
                          item.data.id,
                        target: "__blank",
                        light: "",
                        "icon-color": "#fff",
                      },
                    })
                  }),
                  1
                ),
              ]),
              _c(
                "router-link",
                {
                  staticClass: "item__link",
                  attrs: { to: { name: "DocumentsPage" } },
                },
                [_vm._v(" Все документы ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }