import { render, staticRenderFns } from "./ContactsBlock.vue?vue&type=template&id=bad212be&scoped=true&"
import script from "./ContactsBlock.vue?vue&type=script&lang=js&"
export * from "./ContactsBlock.vue?vue&type=script&lang=js&"
import style0 from "./ContactsBlock.vue?vue&type=style&index=0&id=bad212be&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bad212be",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bad212be')) {
      api.createRecord('bad212be', component.options)
    } else {
      api.reload('bad212be', component.options)
    }
    module.hot.accept("./ContactsBlock.vue?vue&type=template&id=bad212be&scoped=true&", function () {
      api.rerender('bad212be', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/blocks/ContactsBlock.vue"
export default component.exports