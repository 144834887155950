<template>
  <div class="contact">
    <content-wrapper-block>
      <page-title white> контакты </page-title>
      <!-- <card-adress
        title="График работы офиса продаж в праздники"
        work-time="<b style='color: #FFF'>12-14 июня </b> - с 10:00 - 20:00"
      /> -->
      <div class="contact-items">
        <card-adress
          title="ЖК MAINSTREET"
          :address="buildingAddress"
          :work-time="buildingWorkTime"
        >
          <template slot="icon">
            <icon-location :color="'#E14313'" />
          </template>
        </card-adress>
        <card-adress
          title="офис продаж"
          :address="ofiiceAddress"
          :work-time="officeWorkTime"
        >
          <template slot="icon">
            <icon-location :color="'#83B4FF'" />
          </template>
        </card-adress>

        <div class="contact__docs item item_mobile--hide item_line item_indent">
          <div class="item__title">Документация</div>
          <div>
            <documents-item
              v-for="(item, index) in documents"
              :key="index"
              :file-name="item.data.original_name"
              :file-date="item.data.date"
              :file-u-r-l="
                'https://api.gkosnova.tech/public/api/v1/download/' +
                item.data.id
              "
              target="__blank"
              light
              :icon-color="'#fff'"
            />
          </div>
          <router-link class="item__link" :to="{ name: 'DocumentsPage' }">
            Все документы
          </router-link>
        </div>
      </div>
    </content-wrapper-block>

    <div class="contact-map">
      <Map />

      <content-wrapper-block
        class="contact-map contact-map__item contact-map__item_hide--desctop"
      >
        <div class="item">
          <div class="item__title">Документация</div>
          <div class="item__docs">
            <documents-item
              v-for="(item, index) in documents"
              :key="index"
              :file-name="item.data.original_name"
              :file-date="item.data.date"
              :file-u-r-l="
                'https://api.gkosnova.tech/public/api/v1/download/' +
                item.data.id
              "
              target="__blank"
              light
              :icon-color="'#fff'"
            />
          </div>
        </div>
        <router-link class="item__link" :to="{ name: 'DocumentsPage' }">
          Все документы
        </router-link>
      </content-wrapper-block>
    </div>
  </div>
</template>

<script>
import ContentWrapperBlock from "@/components/blocks/ContentWrapperBlock";
import PageTitle from "@/components/elements/PageTitle";
import DocumentsItem from "@/components/elements/DocumentsItem";
import Map from "@/components/elements/Map";
import CardAdress from "@/components/elements/CardAdress";
import IconLocation from "@/components/icons/IconLocation";
// import UiButton from '@/components/ui/UIButton'
import { mapState } from "vuex";

export default {
  name: "ContactsBlock",
  components: {
    ContentWrapperBlock,
    PageTitle,
    CardAdress,
    DocumentsItem,
    IconLocation,
    // UiButton,
    Map,
  },
  props: {
    buildingAddress: {
      type: String,
      default: "",
    },
    buildingWorkTime: {
      type: String,
      default: "",
    },
    ofiiceAddress: {
      type: String,
      default: "",
    },
    officeWorkTime: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState({
      documents: (state) => state.documents.slice(0, 2),
    }),
  },
};
</script>

<style scoped lang="scss">
.contact {
  background: #231f20;

  &__docs {
    width: 33.3%;
  }

  &-items {
    display: flex;
    margin: 30px 0 25px 0;

    .item {
      margin-left: 22px;
      color: #fff;

      @media (min-width: $screen-xs) {
        margin-left: 50px;
      }

      &:first-child {
        margin-left: 0;
      }

      &__title {
        font-size: 26px;
        margin-bottom: 25px;
      }

      .documents-item {
        margin-bottom: 15px;
      }

      &__link {
        font-family: Rubik;
        font-weight: 300;
        font-size: 12px;
        position: absolute;
        bottom: -30px;
        color: #fff;
        &:hover {
          text-decoration: none;
        }
      }
    }
    &__button_hihe--mobile {
      display: none;
      @media (min-width: $screen-md) {
        display: block;
      }
    }

    .item_line {
      position: relative;
      border-bottom: 1px solid #2e2a2b;
    }

    .item_indent {
      margin-left: 60px;

      @media (min-width: $screen-md) {
        margin-left: 100px;
      }
    }

    .item_mobile--hide {
      display: none;
      @media (min-width: $screen-md) {
        display: block;
      }
    }
  }

  &-map {
    margin-top: 0px;
    @media (min-width: $screen-md) {
      margin-top: 60px;
    }
    &__button_hide--desctop {
      display: block;
      margin-top: 30px;

      @media (min-width: $screen-md) {
        display: none;
      }
    }
    &__item {
      margin-top: 15px;

      .item {
        position: relative;
        margin-top: 30px;

        &__link {
          font-family: Rubik;
          font-weight: 300;
          font-size: 12px;
          bottom: -30px;
          color: #fff;
          &:hover {
            text-decoration: none;
          }
        }
        &__title {
          font-size: 26px;
          color: #fff;
          margin-bottom: 13px;
        }
        &__docs {
          border-bottom: 1px solid #2e2a2b;
          margin-bottom: 15px;

          .documents-item {
            margin-bottom: 10px;
          }
        }
      }
      &_hide--desctop {
        display: block;
        padding-bottom: 40px;
        @media (min-width: $screen-xs) {
          padding-bottom: 20px;
        }
        @media (min-width: $screen-md) {
          display: none;
        }
        .item {
          margin-top: 0;
          &__title {
            font-size: 20px;
          }
        }
      }
    }
  }
}
</style>
